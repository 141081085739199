import { Box, Grid, Stack } from '@material-ui/core';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import useIsOnTop from 'utils/hooks/use-is-on-top';
import Context from '../utils/context';

type NavbarProps = {
  scrollableNodeRef?: any; // TODO: fix any
};

const Navbar = ({ scrollableNodeRef }: NavbarProps) => {
  const { logo } = useContext(Context);
  // const navigate = useNavigate();
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const isOnTop = useIsOnTop(scrollableNodeRef);
  return (
    <Grid
      container
      sx={{
        alignItems: 'center',
        bgcolor: isOnTop ? 'grey.600' : 'transparent',
        flexDirection: 'row',
        height: { xs: 56, lg: 67 },
        justifyContent: 'space-between',
        position: 'fixed',
        px: { xs: 4, lg: 8 },
        top: 0,
        transition: '.3s',
        zIndex: 100,
      }}
    >
      <Link to="/">
        <Box
          sx={{
            backgroundImage: `url(${logo})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            height: { xs: 40, lg: 45 },
            width: { xs: 119, lg: 118 },
          }}
        />
      </Link>
      <Stack direction="row" spacing={2}>
        {/* <Button
          color="tertiary"
          variant="contained"
          size={isMobile ? 'small' : 'medium'}
          onClick={() => navigate('/auth/register')}
        >
          Cadastre-se
        </Button> */}
      </Stack>
    </Grid>
  );
};

export default Navbar;
